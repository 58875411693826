document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-trix-character-counter')
  elements.forEach(element => {
    addEventListener('trix-change', event => {
      const target = event.target
      const maxlength = target.getAttribute('maxlength')
      const body = target.editor.getDocument().toString()
      const characterCount = maxlength ? maxlength - body.length : body.length - 1
      target.nextElementSibling.innerText = `Quedan `  +`${characterCount} caracteres`
    })
  })
})
