import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element", "output"];

  copy(event) {
    // Create textarea and copy what it wraps
    const el = document.createElement("textarea");
    el.value = this.elementTarget.textContent;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    // Change text content in target output
    this.outputTarget.textContent = `Código Copiado`;
    this.outputTarget.parentElement.classList.remove("bg-black");
    this.outputTarget.parentElement.classList.add("bg-green-500");
  }
}
