import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.add('hidden-important')
      if (event.target.value == el.getAttribute('data-key'))
        el.classList.remove('hidden-important')
    })
  }
}
