import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.toggle('hidden')
    })
  }

  showhide(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.add('hidden')
      if (event.target.value == el.getAttribute('data-key')) {
        el.classList.remove('hidden')
      }
    })
  }

  st_analysis_stage(event) {
    const currentStep = document.getElementById('st_current_analysis_progress').value
    const currentStepNumber = this.#getStStepNumber(currentStep)
    let nextStepNumber = this.#getStStepNumber(event.target.value)
    let archiveReport = false

    if (nextStepNumber === -1) {
      nextStepNumber = currentStepNumber + 1
      archiveReport = true
    }

    const domReportStep = document.getElementById(`report-state-${nextStepNumber}`)

    const domReportStepDescription = domReportStep.getElementsByClassName('text-base')[0]
    const reportStepDescription = domReportStepDescription.getAttribute('description')

    const domCircle = domReportStep.getElementsByClassName('rounded-full')[0]
    domCircle.classList.remove('bg-blue-200')
    domCircle.classList.remove('bg-blue-700')
    domCircle.classList.remove('bg-red-950')

    const domCircleText = domReportStep.getElementsByClassName('leading-none')[0]
    domCircleText.classList.remove('text-gray-900')
    domCircleText.classList.add('text-white')

    const domLineDown = domReportStep.getElementsByClassName('line-down')[0]
    if (domLineDown) {
      domLineDown.classList.add('bg-blue-200')
    }

    this.#toggleLayerOverDomStep(nextStepNumber, false)

    if (!archiveReport) {
      domCircle.classList.add('bg-blue-700')
      domReportStepDescription.innerText = reportStepDescription

      this.#toggleLayerOverDomStep(nextStepNumber)
    } else {
      domCircle.classList.add('bg-red-950')
      domReportStepDescription.innerText = 'Se dispuso su archivo'
      if (domLineDown) {
        domLineDown.classList.remove('bg-blue-200')
      }
    }
  }

  #toggleLayerOverDomStep(nextStepNumber, hidden = true) {
    for (let index = nextStepNumber + 1; index <= 4; index++) {
      const domStep = document.getElementById(`label-transparent-${index}`)
      if (hidden) {
        domStep.classList.add('hidden')
      } else {
        domStep.classList.remove('hidden')
      }
    }
  }

  #getStStepNumber(step) {
    let number

    switch (step) {
      case 'st_preliminar':
        number = 1
        break

      case 'st_prequalification':
        number = 2
        break

      case 'st_procedure':
        number = 3
        break

      case 'st_pad':
        number = 4
        break

      case 'st_request_archive':
        number = -1
        break

      default:
        number = 0
        break
    }

    return number
  }
}
