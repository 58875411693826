import Rails from "@rails/ujs";

document.addEventListener("change", (event) => {
  if (!event.target.matches(".js-submit-on-click")) {
    return;
  }

  Rails.fire(event.target.closest("form"), "submit");
});

document.addEventListener("input", (event) => {
  if (!event.target.matches(".js-submit-on-input")) {
    return;
  }

  Rails.fire(event.target.closest("form"), "submit");
});
