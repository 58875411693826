import { Controller } from 'stimulus'

export default class extends Controller {
  // use target element to disable actionText
  static targets = ['element']
  connect() {
    const buttonGroups = document.querySelector('div[data-controller=trix-only-link] > trix-toolbar > .trix-button-row')
    buttonGroups.querySelector('.trix-button-group--block-tools').remove()
    buttonGroups.querySelector('.trix-button-group--file-tools').remove()
    buttonGroups.querySelector('.trix-button-group--text-tools > .trix-button--icon-bold').remove()
    buttonGroups.querySelector('.trix-button-group--text-tools > .trix-button--icon-italic').remove()
    buttonGroups.querySelector('.trix-button-group--text-tools > .trix-button--icon-strike').remove()

    if (this.hasElementTarget) {
      if (this.elementTarget.getAttribute('disabled')) {
        console.log(this.elementTarget)
        this.elementTarget.contentEditable = false
        this.elementTarget.classList.add('input_like', 'disabled')
        buttonGroups.remove()
      }
    }
  }
}
