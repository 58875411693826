import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.hideMessage();
  }

  hideMessage() {
    setTimeout(() => {
      this.elementTarget.classList.add("hidden");
    }, 4000);
  }
}
