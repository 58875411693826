// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../styles/application.scss";
import "controllers";

import "src/change_text";
import "src/submit_on_click_or_input";
import "src/character_counter.js";
import "src/choices";
import "trix";

//require("trix")
//require("@rails/actiontext")
document.addEventListener("trix-file-accept", (e) => {
  e.preventDefault();
});
document.querySelector("html").style.scrollBehavior = "smooth"
