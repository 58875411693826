import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  selected(evt) {
    let url = evt.currentTarget.dataset.userUrl
    let institution_id = evt.currentTarget.value
    let new_url = this.updateQueryStringParameter(url, 'institution_id', institution_id)
    this.validateUserOII(new_url)
  }

  async validateUserOII(url) {
    let importantBox = document.getElementById('importantBox')
    let optionalText = document.getElementById('optionalText')
    let spanSendButton = document.getElementById('spanSendButton')
    let sendButton = document.getElementById('sendButton')
    let institutionUsesService = document.querySelector('[id$="institution_uses_service"]')
    let archivedType = document.querySelector('[id$="archived_type"]')
    sendButton.disabled = true
    spanSendButton.innerText = 'Verificando...'
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const items = await response.json()
      if (items.length) {
        importantBox.style.display = 'none'
        optionalText.classList.remove('hidden')
        spanSendButton.innerText = 'Enviar'
        institutionUsesService.value = true
        archivedType.value = 'derived_institution'
      } else {
        importantBox.style.display = 'block'
        optionalText.classList.add('hidden')
        spanSendButton.innerText = 'Derivar'
        institutionUsesService.value = null
        archivedType.value = 'institution_unused_services'

        const institutionSelect = document.getElementById('report_notes_attributes_0_institution_id')
        const institutionName = institutionSelect.options[0].innerText
        let textareaValue = document.getElementById('report_notes_attributes_0_body').value
        textareaValue = textareaValue.replace('_INSTITUTION_NAME_', institutionName)
        document.getElementById('report_notes_attributes_0_body').value = textareaValue
      }
      sendButton.disabled = false
    } catch (err) {
      console.error(err)
    }
  }

  updateQueryStringParameter(uri, key, value) {
    let re = new RegExp('([?|&])' + key + '=.*?(&|$)', 'i')
    let separator = uri.indexOf('?') !== -1 ? '&' : '?'
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2')
    } else {
      return uri + separator + key + '=' + value
    }
  }
}
