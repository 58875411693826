import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  enable(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.remove('span-block')
    })
  }

  disable(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.add('span-block')
    })
  }
}