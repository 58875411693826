import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  selected(evt) {
    let requestArchive = evt.currentTarget;
    let archivedType = document.querySelector('[id$="archived_type"]');
    (requestArchive.checked) ? archivedType.value = 'st_request_archive' : archivedType.value = ''
  }

}