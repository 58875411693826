import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["parent", "child", "default", "all"];

  uncheckAll() {
    let allCheckbox = document.querySelector(
      '[data-target="checkbox-family.all"]'
    );
    allCheckbox.checked = false;
  }

  checkAll() {
    let allCheckbox = document.querySelector(
      '[data-target="checkbox-family.all"]'
    );
    let all = document.querySelectorAll('[id^="report_filter_states_"]');
    let countChecked = 0;
    for (let checkbox of all) {
      if (allCheckbox.id != checkbox.id) {
        if (checkbox.checked) {
          countChecked += 1;
        }
      }
    }
    if (countChecked == all.length - 1) {
      allCheckbox.checked = true;
    }
  }

  toggle(evt) {
    evt.preventDefault();
    if (!this.hasParentTarget) return;

    if (evt.currentTarget.dataset.target == "checkbox-family.parent") {
      for (let child of this.childTargets) {
        let arrayChild = evt.currentTarget.value.split(",");
        if (arrayChild.includes(child.value)) {
          if (!evt.currentTarget.checked) {
            child.checked = false;
            this.uncheckAll();
          } else {
            child.checked = true;
            this.checkAll();
          }
        }
      }
    } else if (evt.currentTarget.dataset.target == "checkbox-family.child") {
      for (let parent of this.parentTargets) {
        let arrayChild = parent.value.split(",");
        let countCheckedChildren = 0;
        if (arrayChild.includes(evt.currentTarget.value)) {
          for (let child of arrayChild) {
            let input = "report_filter_states_" + child;
            let childinput = document.getElementById(input);
            if (childinput.checked) {
              countCheckedChildren += 1;
            }
          }
          if (arrayChild.length == countCheckedChildren) {
            parent.checked = true;
            this.checkAll();
          } else {
            parent.checked = false;
            this.uncheckAll();
          }
        }
      }
    } else if (evt.currentTarget.dataset.target == "checkbox-family.all") {
      let all = document.querySelectorAll('[id^="report_filter_states_"]');
      for (let checkbox of all) {
        if (evt.currentTarget.id != checkbox.id) {
          if (evt.currentTarget.checked) {
            checkbox.checked = true;
          } else {
            checkbox.checked = false;
          }
        }
      }
    } else if (evt.currentTarget.dataset.target == "checkbox-family.default") {
      if (!evt.currentTarget.checked) {
        this.uncheckAll();
      } else {
        this.checkAll();
      }
    }
  }
}
